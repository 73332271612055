import { combineReducers } from '@reduxjs/toolkit'

import userReducer from './user'
import siteReducer from './site'
import conversationReducer from './conversation'
import authenticationReducer from './authentication'
import userSettingsReducer from './userSettings'
import goalsReducer from './goals'

const rootReducer = combineReducers({
    user: userReducer,
    site: siteReducer,
    conversation: conversationReducer,
    authentication: authenticationReducer,
    userSettings: userSettingsReducer,
    goals: goalsReducer
})

export default rootReducer
