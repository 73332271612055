import { Box, Typography } from '@mui/material'
import styles from './styles'
import useRegularNavbar from './RegularNavbar.hooks'
import { useNavbarStyles } from '../Navbar.hooks'
import { IRegularNavbarProps } from './RegularNavbar.data'
import SpeakableLogo from '../../SpeakableLogo'
import { SpeakableLogoType } from '../../SpeakableLogo/SpeakableLogo.data'
import { useNavigate } from 'react-router-dom'

const RegularNavbar = ({ withoutButtons }: IRegularNavbarProps): JSX.Element => {
    const { onClickHome, onClickProfile, practiceTextStyles, profileTextStyles } = useRegularNavbar()
    const { containerStyle, placeholderStyles } = useNavbarStyles()
    const navigate = useNavigate()

    return (
        <Box>
            <Box sx={containerStyle}>
                <SpeakableLogo type={SpeakableLogoType.LIGHT} onClick={() => navigate('/home')} />
                {withoutButtons !== true && (
                    <Box sx={styles.linksRow}>
                        <Box sx={styles.linkTextContainer} onClick={onClickHome}>
                            <Typography sx={practiceTextStyles}>{'Home'}</Typography>
                        </Box>
                        <Box sx={styles.linkTextContainer} onClick={onClickProfile}>
                            <Typography sx={profileTextStyles}>{'Profile'}</Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box sx={placeholderStyles} />
        </Box>
    )
}

export default RegularNavbar
