import { GLTF } from 'three-stdlib'
import { AvatarAction, VisemeInformation } from '../../../store/conversation/data'

export type Node =
    | 'EyeLeft'
    | 'EyeRight'
    | 'Wolf3D_Head'
    | 'Wolf3D_Teeth'
    | 'Wolf3D_Hair'
    | 'Wolf3D_Body'
    | 'Wolf3D_Outfit_Bottom'
    | 'Wolf3D_Outfit_Footwear'
    | 'Wolf3D_Outfit_Top'
    | 'Hips'

export type Material =
    | 'Wolf3D_Eye'
    | 'Wolf3D_Skin'
    | 'Wolf3D_Teeth'
    | 'Wolf3D_Hair'
    | 'Wolf3D_Body'
    | 'Wolf3D_Outfit_Bottom'
    | 'Wolf3D_Outfit_Footwear'
    | 'Wolf3D_Outfit_Top'

export type GLTFResult = GLTF & GLTFNodesAndMaterials

export type GLTFNodesAndMaterials = {
    nodes: {
        EyeLeft: THREE.SkinnedMesh
        EyeRight: THREE.SkinnedMesh
        Wolf3D_Head: THREE.SkinnedMesh
        Wolf3D_Teeth: THREE.SkinnedMesh
        Wolf3D_Hair: THREE.SkinnedMesh
        Wolf3D_Body: THREE.SkinnedMesh
        Wolf3D_Outfit_Bottom: THREE.SkinnedMesh
        Wolf3D_Outfit_Footwear: THREE.SkinnedMesh
        Wolf3D_Outfit_Top: THREE.SkinnedMesh
        Hips: THREE.Bone
    }
    materials: {
        Wolf3D_Eye: THREE.MeshStandardMaterial
        Wolf3D_Skin: THREE.MeshStandardMaterial
        Wolf3D_Teeth: THREE.MeshStandardMaterial
        Wolf3D_Hair: THREE.MeshStandardMaterial
        Wolf3D_Body: THREE.MeshStandardMaterial
        Wolf3D_Outfit_Bottom: THREE.MeshStandardMaterial
        Wolf3D_Outfit_Footwear: THREE.MeshStandardMaterial
        Wolf3D_Outfit_Top: THREE.MeshStandardMaterial
    }
}

export type Child = THREE.Object3D<THREE.Object3DEventMap> & {
    isSkinnedMesh: any
    morphTargetDictionary: any
    morphTargetInfluences: any
}

export const azureToOculusVisemeMap: Map<number, string> = new Map([
    [0, 'viseme_sil'],
    [1, 'viseme_aa'],
    [2, 'viseme_aa'],
    [3, 'viseme_O'],
    [4, 'viseme_E'],
    [5, 'viseme_RR'],
    [6, 'viseme_CH'], // double check this out
    [7, 'viseme_U'],
    [8, 'viseme_O'],
    [9, 'viseme_aa'],
    [10, 'viseme_O'],
    [11, 'viseme_aa'], // double check
    [12, 'viseme_E'], // double check
    [13, 'viseme_RR'],
    [14, 'viseme_nn'],
    [15, 'viseme_SS'],
    [16, 'viseme_CH'],
    [17, 'viseme_TH'],
    [18, 'viseme_FF'],
    [19, 'viseme_DD'],
    [20, 'viseme_kk'],
    [21, 'viseme_PP']
])

export enum Voice {
    ANDREW_NEURAL = 'en-US-AndrewNeural',
    AVA_NEURAL = 'en-US-AvaNeural',
    BRIAN_NEURAL = 'en-US-BrianNeural',
    GUY_NEURAL = 'en-US-GuyNeural',
    EMMA_NEURAL = 'en-US-EmmaNeural',
    DAVIS_NEURAL = 'en-US-DavisNeural',
    JENNY_NEURAL = 'en-US-JennyNeural',
    ARIA_NEURAL = 'en-US-AriaNeural',
    JANE_NEURAL = 'en-US-JaneNeural',
    JASON_NEURAL = 'en-US-JasonNeural',
    BRANDON_NEURAL = 'en-US-BrandonNeural',
    TONY_NEURAL = 'en-US-TonyNeural'
}

export enum AvatarState {
    STANDING,
    THINKING,
    TALKING
}

export enum Gender {
    MALE = 'Male',
    FEMALE = 'Female'
}

export interface Avatar {
    id: number
    name: string
    gender: Gender
    voice: Voice
    complexity: 'easy' | 'medium' | 'advanced'
    personality: string
    background: string
    mesh: Node[]
}

export const allAvatars: Array<Avatar> = [
    {
        id: 0,
        name: 'Rachel',
        gender: Gender.FEMALE,
        voice: Voice.AVA_NEURAL,
        complexity: 'easy',
        personality:
            'You are Rachel, a young, friendly woman who speaks in a peppy and upbeat tone. You are emphatic and understanding. You speak in a plain, simple manner.',
        background:
            'If asked about your background, you are currently a nursing student with aspirations of being an ICU nurse. You grew up in a small midwestern town, and enjoy knitting, cooking, and playing the guitar in your free time.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 1,
        name: 'Carlos',
        gender: Gender.MALE,
        voice: Voice.BRIAN_NEURAL,
        complexity: 'easy',
        personality:
            'You are Carlos. You are a kind, generous man who likes to compliment other people. You speak in short, simple sentences.',
        background:
            'If asked about your background, you are the owner and operator of an Empanada shop in North Carolina. You moved to North Carolina from Argentina for better economic conditions. You have two high school age kids and a wife. In your free time, you enjoy watching soccer, playing soccer, and volunteering with your church.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 2,
        name: 'Marcus',
        gender: Gender.MALE,
        voice: Voice.DAVIS_NEURAL,
        complexity: 'medium',
        personality:
            'You are Marcus, a thoughtful, contemplative, academic man. You enjoy talking about history and reminiscing about past times. You enjoy sharing your opinion and asking other people about theirs.',
        background:
            'If asked about your background, you are a retired university professor of history from New Orleans. Your focus area was on the Vietnam war. In your free time, you enjoy reading historical accounts, watching documentaries, playing with your dogs, and mentoring high school students with an interest in history.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 3,
        name: 'Vivian',
        gender: Gender.FEMALE,
        voice: Voice.JANE_NEURAL,
        complexity: 'advanced',
        personality:
            'You are Vivian, a person with a love of science. You speak in precise, informative sentences. You enjoy learning why people believe or think certain things.',
        background:
            'If asked about your background, you are a scientist with a Ph.D. in biochemistry from Boston. You work in a research lab focusing on genomics research. In your free time, you enjoy reading scientific journals, woodworking, and practicing tai chi.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 4,
        name: 'Luna',
        gender: Gender.FEMALE,
        voice: Voice.ARIA_NEURAL,
        complexity: 'advanced',
        personality:
            'You are Luna, a free spirit that enjoys nature, the environment and travel. You are a kind person that cares about the feelings of others.',
        background:
            'If asked about your background, you are a freelance artist from the Pacific Northwest. You are a painter with several successful pieces. In your free time, you enjoy stargazing, writing poetry, and attending local art and thrift clothing events.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 5,
        name: 'James',
        gender: Gender.MALE,
        voice: Voice.ANDREW_NEURAL,
        complexity: 'easy',
        personality:
            'You are James, a down-to-earth guy that speaks in a straightforward, simple manner. You talk in short, simple sentences.',
        background:
            'If asked about your background, you are a highschool teacher from the West Coast. You teach in the same town you grew up in. In your free time, you coach the school’s soccer team, watch 80’s movies, and train for triathlons.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 6,
        name: 'Mark',
        gender: Gender.MALE,
        voice: Voice.GUY_NEURAL,
        complexity: 'easy',
        personality:
            'You are Mark, a jovial and outgoing man. You easily make new friends. You are adventurous and love trying new things, often seeking out adrenaline-pumping activities for excitement. You speak in shorter sentences.',
        background:
            'If asked about your background, you work as a firefighter. You have a strong sense of duty and take pride in helping others. You grew up in a large family and learned the value of camaraderie at an early age. In your free time, you enjoy hiking, camping, and attending live music events.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 7,
        name: 'Myra',
        gender: Gender.FEMALE,
        voice: Voice.EMMA_NEURAL,
        complexity: 'easy',
        personality:
            'You are Myra, a gentle and compassionate soul. You listen more than you talk. You are deeply empathetic, try to understand others’ feelings and perspectives, and find joy in the simple things. You are a succinct, caring speaker.',
        background:
            'If asked about your background, you are a librarian in a small town. You have always had a passion for books, which goes back to your experience growing up as a girl in this town and going to this library. In your free time, you volunteer at the local animal shelter and tend to your garden.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 8,
        name: 'Evelyn',
        gender: Gender.FEMALE,
        voice: Voice.JENNY_NEURAL,
        complexity: 'medium',
        personality:
            'You are Evelyn, a kind-hearted and compassionate woman. You have a gentle spirit and a nurturing nature that makes you beloved by all who know you. You are a great listener and offer sage advice drawn from your own life experiences. You value family above all else.',
        background:
            'If asked about your background, you are a retired receptionist at a doctor’s office. You have seen it all in your years on the job and have countless stories to share. You grew up in a tight-knit community and have remained close with your childhood friends throughout your life. In your free time, you enjoy your book club, baking, and hosting gatherings for your family and friends.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 9,
        name: 'Dimitri',
        gender: Gender.MALE,
        voice: Voice.JASON_NEURAL,
        complexity: 'advanced',
        personality:
            'You are Dimitri, a suave individual that exudes confidence and charm. You are well-spoken and articulate, with a knack for diplomacy, persuasion, and navigating social situations with finesse.',
        background:
            'You work as a diplomatic liaison for the World Health Organization, negotiating agreements and fostering diplomatic relations between countries. You hold degrees in political science and international relations. You grew up in a diplomatic family, where you developed a keen understanding of global affairs from a young age. In his free time, you enjoy attending cultural events and practicing martial arts.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 10,
        name: 'Alex',
        gender: Gender.MALE,
        voice: Voice.TONY_NEURAL,
        complexity: 'advanced',
        personality:
            'You are Alex, a charming and articulate conversationalist. You possess a natural ability to engage others with your wit and intellect. You enjoy discussing a wide range of topics, from philosophy to pop culture, and you’re always eager to learn something new. You have a knack for storytelling and often captivates your audience with anecdotes and insights.',
        background:
            'If asked about your background, you work as a journalist for the New York Times. You have a passion for storytelling and believe in the power of journalism to inspire change. You grew up in a multicultural environment, which fueled your curiosity about the world. In your free time, you enjoy attending literary events, exploring new cafes, and writing fiction.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    },
    {
        id: 11,
        name: 'William',
        gender: Gender.MALE,
        voice: Voice.BRIAN_NEURAL,
        complexity: 'advanced',
        personality:
            'You are William, a determined and ambitious individual. You have a strong work ethic, are confident and assertive, and are unafraid to speak your mind and stand up for what you believe in.',
        background:
            'If asked about your background, you work as a lawyer in a prestigious law firm. You studied law at Harvard and excelled in your studies. You grew up in a bustling city and was raised by a single mother, who instilled in you the values of hard work and perseverance. In your free time, you enjoy crossfit, reading, and whiskey tasting.',
        mesh: [
            'EyeLeft',
            'EyeRight',
            'Wolf3D_Head',
            'Wolf3D_Teeth',
            'Wolf3D_Hair',
            'Wolf3D_Body',
            'Wolf3D_Outfit_Bottom',
            'Wolf3D_Outfit_Footwear',
            'Wolf3D_Outfit_Top'
        ]
    }
]

export const avatarMap: Map<number, Avatar> = new Map([
    [0, allAvatars[0]], // Rachel
    [1, allAvatars[1]], // Carlos
    [2, allAvatars[2]], // Marcus
    [3, allAvatars[3]], // Vivian
    [4, allAvatars[4]], // Luna
    [5, allAvatars[5]], // James
    [6, allAvatars[6]], // Mark
    [7, allAvatars[7]], // Myra
    [8, allAvatars[8]], // Evelyn
    [9, allAvatars[9]], // Dimitri
    [10, allAvatars[10]], // Alex
    [11, allAvatars[11]] // William
])

export interface IAvatarProps {
    avatarId: number
    setIsLoadingAvatar?: React.Dispatch<React.SetStateAction<boolean>>
    avatarAction: AvatarAction
    visemeInformation: VisemeInformation[]
}
