import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { LandingPageContext, Tab } from './LandingPage.data'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createEvent } from '../../api/analytics/api'
import { useGetAuthentication } from '../../store/selectors/authenticationSelector'
import { useAppDispatch } from '../../store/hooks'
import { setReferrer } from '../../store/site'
import { Referrer } from '../../store/site/data'
import { useGetSite } from '../../store/selectors/siteSelector'

export const useLandingPage = () => {
    // references to the HTML elements that represent the start of each section
    const navbarRef = useRef<HTMLElement | undefined>()
    const productRef = useRef<HTMLElement | undefined>()
    const featuresRef = useRef<HTMLElement | undefined>()
    const storyRef = useRef<HTMLElement | undefined>()
    const pricingRef = useRef<HTMLElement | undefined>()
    const contactRef = useRef<HTMLElement | undefined>()
    const [currentlySelectedTab, setCurrentlySelectedTab] = useState<Tab>('Product')
    const [allTabsAndPositions, setAllTabsAndPositions] = useState<[Tab, number][] | undefined>()
    const [showSidebar, setShowSidebar] = useState<boolean>(false)
    const { isAuthenticated } = useGetAuthentication()

    // when a user clicks on a tab, make it selected and also scroll to the beginning
    // of the corresponding section
    const onTabClick = useCallback(
        (tab: Tab) => {
            createEvent(
                { eventId: `landing-page-footer-tab-${tab.toLowerCase()}`, time: new Date().getTime() },
                isAuthenticated === true
            )
            let correspondingRef = undefined
            switch (tab) {
                case 'Product':
                    correspondingRef = productRef
                    break
                case 'Features':
                    correspondingRef = featuresRef
                    break
                case 'Contact':
                    correspondingRef = contactRef
                    break
                case 'Pricing':
                    correspondingRef = pricingRef
                    break
                case 'Story':
                    correspondingRef = storyRef
                    break
            }

            if (correspondingRef.current !== undefined) {
                window.scrollTo({ top: correspondingRef.current.offsetTop, behavior: 'smooth' })
            }
        },
        [allTabsAndPositions]
    )

    // we can assume that all references are set
    const onWindowScroll = useCallback(
        (_event: Event) => {
            if (allTabsAndPositions === undefined) throw new Error("This line shouldn't be reached!")

            const scrollValue = Math.max(window.scrollY, 0)
            const filteredTabs = allTabsAndPositions.filter((val) => scrollValue >= val[1])
            const currentTab = (filteredTabs[filteredTabs.length - 1] as [Tab, number])[0]
            setCurrentlySelectedTab(currentTab)
        },
        [allTabsAndPositions]
    )

    // listen for scrolling
    useEffect(() => {
        if (allTabsAndPositions) {
            window.addEventListener('scroll', onWindowScroll)
            return () => window.removeEventListener('scroll', onWindowScroll)
        }
    }, [allTabsAndPositions])

    // listen for when all refs have been set to their elements
    useEffect(
        () => {
            const allRefsSet = [navbarRef, productRef, featuresRef, contactRef, pricingRef, storyRef]
                .map((ref) => ref.current)
                .every((val) => val !== undefined)

            if (allRefsSet) {
                const allRefs: [Tab, React.MutableRefObject<HTMLElement | undefined>][] = [
                    ['Story', storyRef],
                    ['Product', productRef],
                    ['Contact', contactRef],
                    ['Pricing', pricingRef],
                    ['Features', featuresRef]
                ]
                const allTabsAndPositions = (
                    allRefs.map((values) => [values[0], (values[1].current as HTMLElement).offsetTop]) as [
                        Tab,
                        number
                    ][]
                ).sort((a, b) => a[1] - b[1])
                setAllTabsAndPositions(allTabsAndPositions)
            }
        },
        [navbarRef, productRef, storyRef, contactRef, pricingRef, featuresRef].map((ref) => ref.current?.offsetHeight)
    )

    return {
        navbarRef,
        productRef,
        featuresRef,
        storyRef,
        pricingRef,
        contactRef,
        currentlySelectedTab,
        onTabClick,
        showSidebar,
        setShowSidebar
    }
}

export const useLandingPageContext = () => {
    const landingPageContext = useContext(LandingPageContext)

    if (landingPageContext === undefined) {
        throw new Error('LandingPageContext must be used with a LandingPage context')
    }

    return landingPageContext
}

// This hook is used to track the referrer of Speakable
export const useTrackReferrer = () => {
    const { referrer: existingReferrer } = useGetSite()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.has('referrer')) {
            const referrer = queryParams.get('referrer')
            if (referrer === 'googleads' && existingReferrer !== Referrer.GOOGLE_ADS) {
                createEvent({ eventId: 'google-ads-referral', eventType: 'load', time: new Date().getTime() }, false)
                dispatch(setReferrer(Referrer.GOOGLE_ADS))
            }
        }
    }, [])
}
