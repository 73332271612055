import storage from 'redux-persist/lib/storage'

export enum ModalType {
    LOGIN = 'login',
    SIGNUP = 'signup',
    LANDING_PAGE_VIDEO = 'landing page video',
    FORGOT_PASSWORD = 'forgot password',
    PLAYBACK_BLOCKED = 'playback blocked',
    UNLOCK_BADGE = 'unlock badge',
    PRACTICE_GOAL = 'practice goal'
}

export enum Referrer {
    GOOGLE_ADS = 'google ads'
}

export type SliceState = {
    currentModal: ModalType | undefined // whats the current modal that's being displayed?
    currentModalMetadata: any | undefined // metadata for the current modal
    conversationStatus: 'operational' | 'down' // are conversations operational or are they down?
    referrer: Referrer | undefined // where did the user come from?
}

export const initialState: SliceState = {
    currentModal: undefined,
    currentModalMetadata: undefined,
    conversationStatus: 'operational',
    referrer: undefined
}

export const sitePersistConfig = {
    key: 'site',
    storage,
    whitelist: ['referrer']
}
