import { SxProps, Theme } from '@mui/material'
import useIsMobile from '../../hooks/useIsMobile'
import { DESKTOP_PAGE_SIDE_PADDING, MOBILE_PAGE_SIDE_PADDING } from '../../utils/constants'
import { routesForLandingPage } from '../../utils/routes'
import styles from './styles'
import { useGetSite } from '../../store/selectors/siteSelector'

const usePageWrapper = () => {
    const isMobile = useIsMobile()
    const sidePadding = isMobile ? MOBILE_PAGE_SIDE_PADDING : DESKTOP_PAGE_SIDE_PADDING
    const isOnLandingPage = routesForLandingPage.includes(window.location.pathname)
    const containerStyles = isOnLandingPage
        ? null
        : ({
              ...styles.container,
              pl: sidePadding,
              pr: sidePadding,
              pb: sidePadding
          } as SxProps<Theme>)

    return { containerStyles }
}

const useBanner = () => {
    const { conversationStatus } = useGetSite()

    return (
        conversationStatus === 'down' &&
        (['/home', '/conversation'].includes(window.location.pathname) ||
            window.location.pathname.includes('/conversation-details'))
    )
}

export default usePageWrapper

export { useBanner }
