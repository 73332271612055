import { Box, Typography } from '@mui/material'
import { BOLD_FONT_WEIGHT } from '../../utils/constants'

const Banner = (): JSX.Element => {
    return (
        <Box
            sx={{
                display: 'flex',
                backgroundColor: 'red',
                position: 'fixed',
                left: 0,
                right: 0,
                zIndex: 20,
                p: 5,
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Typography
                sx={{ color: 'white', fontWeight: BOLD_FONT_WEIGHT }}
            >{`We're currently experiencing issues with all conversations. Please try again later!`}</Typography>
        </Box>
    )
}

export default Banner
