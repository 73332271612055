import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import styles from './styles'
import SpeakableButton from '../../components/SpeakableButton'
import useOnboardingPage from './OnboardingPage.hooks'
import { useState } from 'react'

const OnboardingPage = (): JSX.Element => {
    const { onGetStarted, isCompletingOnboarding, referrer, setReferrer, canContinue } = useOnboardingPage()

    return (
        <Box sx={styles.container}>
            <Box sx={styles.leftContainer}>
                <Typography variant={'h2'} sx={styles.titleText}>
                    {'Welcome to Speakable!'}
                </Typography>
                <Typography
                    variant={'h4'}
                    sx={styles.normalText}
                >{`Thank you for joining us! Please watch this short introductory video before you get started.`}</Typography>
                <OnboardingPage.ReferrerForm referrer={referrer} setReferrer={setReferrer} />
                <SpeakableButton
                    id={'onboarding-get-started'}
                    text={'Get Started!'}
                    onClick={onGetStarted}
                    loading={isCompletingOnboarding}
                    variant={'important'}
                    disabled={!canContinue}
                />
            </Box>
            <Box sx={styles.rightContainer}>
                <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/2FfsLzt09Rg?si=4r61cB4ujwgnAIdI`}
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            </Box>
        </Box>
    )
}

const ReferrerForm = ({
    referrer,
    setReferrer
}: {
    referrer: string
    setReferrer: React.Dispatch<React.SetStateAction<string>>
}): JSX.Element => {
    const handleChange = (event: any) => {
        setReferrer(event.target.value)
    }

    return (
        <Box sx={styles.referrerFormContainer}>
            <Typography variant={'h4'} sx={styles.normalText}>
                <span style={{ color: 'red' }}>{'* '}</span>
                <span>{'How did you hear about us?'}</span>
            </Typography>
            <FormControl fullWidth>
                <InputLabel>Referrer</InputLabel>
                <Select value={referrer} label="Age" onChange={handleChange}>
                    <MenuItem value={'Speech Therapist'}>Speech Therapist</MenuItem>
                    <MenuItem value={'Family/Friend'}>Family/Friend</MenuItem>
                    <MenuItem value={'Internet Search'}>Internet Search</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

OnboardingPage.ReferrerForm = ReferrerForm

export default OnboardingPage
