import { Box } from '@mui/material'
import NavBar from '../../components/Navbar'
import usePageWrapper, { useBanner } from './PageWrapper.hooks'
import ModalManager from '../../components/ModalManager'
import { NavbarVariant } from '../../components/Navbar/Navbar.data'
import Banner from './Banner'

type WrapperPageProps = {
    children: string | JSX.Element | JSX.Element[]
    navbarVariant: NavbarVariant
}

const PageWrapper = ({ children, navbarVariant }: WrapperPageProps): JSX.Element => {
    const { containerStyles } = usePageWrapper()
    const displayBanner = useBanner()

    return (
        <Box sx={containerStyles}>
            {displayBanner && <Banner />}
            <NavBar variant={navbarVariant} />
            {children}
            <ModalManager />
        </Box>
    )
}

export default PageWrapper
