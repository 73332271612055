import { Box, SxProps, Theme, Typography } from '@mui/material'
import { forwardRef } from 'react'
import styles from './styles'
import { useLandingPageProductSection, useVideoContainer } from './LandingPageProductSection.hooks'
import { blurb, tagline } from './LandingPageProductSection.data'
import PatternOrange from '../../../assets/images/LandingPage/PatternOrange.svg'
import PatternBlue from '../../../assets/images/LandingPage/PatternBlue.svg'
import LandingPageButton from '../LandingPageButton'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import useIsMobile from '../../../hooks/useIsMobile'
import SpeakableImage from '../../SpeakableImage/SpeakableImage'
import { useGetAuthentication } from '../../../store/selectors/authenticationSelector'

/**
 * This component displays the content on the landing page that the user sees
 * immediately when they get to the page. This element should take up the entire
 * viewport outside of the navbar on page-load.
 */
const LandingPageProductSection = (): JSX.Element => {
    const {
        productRef,
        containerStyle,
        informationContainerStyle,
        titleTextVariant,
        blurbTextVariant,
        onClick,
        isButtonEnabled,
        taglineTextStyles
    } = useLandingPageProductSection()
    const { isAuthenticated } = useGetAuthentication()

    return (
        <Box sx={containerStyle} ref={productRef}>
            <Box sx={informationContainerStyle}>
                <Typography sx={taglineTextStyles} variant={titleTextVariant}>
                    {tagline}
                </Typography>
                <Typography sx={styles.blurbText} variant={blurbTextVariant}>
                    {blurb}
                </Typography>
                <LandingPageButton
                    id={'product-section-button'}
                    text={
                        isButtonEnabled
                            ? isAuthenticated === true
                                ? 'Practice'
                                : 'Get Started'
                            : 'Only Available on Desktop'
                    }
                    onClick={onClick}
                    disabled={!isButtonEnabled}
                />
            </Box>
            <LandingPageProductSection.VideoContainer />
        </Box>
    )
}

const VideoContainer = (): JSX.Element => {
    const onWatchVideo = useVideoContainer()
    const isMobile = useIsMobile()

    return (
        <Box
            onClick={onWatchVideo}
            sx={
                {
                    ...styles.imageContainer,
                    ...(isMobile ? { width: '80%' } : {})
                } as SxProps<Theme>
            }
        >
            <SpeakableImage
                imageSx={styles.desktopImage}
                skeletonSx={styles.desktopImage}
                src={'/images/LandingPageVideoThumbnail.png'}
            />
            <SpeakableImage imageSx={styles.patternTopImage} src={PatternOrange} disableSkeleton={true} />
            <SpeakableImage imageSx={styles.patternBottomImage} src={PatternBlue} disableSkeleton={true} />
            <Box sx={styles.playButtonContainer}>
                <PlayArrowRoundedIcon sx={styles.playButtonIconFront} />
                <PlayArrowRoundedIcon sx={styles.playButtonIconBack} />
            </Box>
        </Box>
    )
}

LandingPageProductSection.VideoContainer = VideoContainer

export default forwardRef(LandingPageProductSection)
