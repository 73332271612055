import { SxProps, Theme } from '@mui/material'
import styles from './styles'
import { useNavigate } from 'react-router-dom'
import { createEvent } from '../../../api/analytics/api'

const useRegularNavbar = () => {
    const navigate = useNavigate()
    const onClickHome = () => {
        createEvent({ eventId: 'navbar-home', time: new Date().getTime() })
        navigate('/home')
    }
    const onClickProfile = () => {
        createEvent({ eventId: 'navbar-profile', time: new Date().getTime() })
        navigate('/profile/dashboard')
    }

    const pathname = window.location.pathname
    const practiceTextStyles = {
        ...styles.linkText,
        ...(pathname === '/home' ? styles.selectedLinkText : {})
    } as SxProps<Theme>
    const profileTextStyles = {
        ...styles.linkText,
        ...(pathname.includes('/profile') ? styles.selectedLinkText : {})
    } as SxProps<Theme>

    return { onClickHome, onClickProfile, practiceTextStyles, profileTextStyles }
}

export default useRegularNavbar
