import { MutableRefObject, createContext } from 'react'

export type Tab = 'Product' | 'Features' | 'Story' | 'Pricing' | 'Contact'

export type LandingPageContextType = {
    navbarRef: MutableRefObject<HTMLElement | undefined>
    productRef: MutableRefObject<HTMLElement | undefined>
    featuresRef: MutableRefObject<HTMLElement | undefined>
    storyRef: MutableRefObject<HTMLElement | undefined>
    pricingRef: MutableRefObject<HTMLElement | undefined>
    contactRef: MutableRefObject<HTMLElement | undefined>
    currentlySelectedTab: Tab
    onTabClick: (tab: Tab) => void
    showSidebar: boolean
    setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>
}

export const LandingPageContext = createContext<LandingPageContextType | undefined>(undefined)
