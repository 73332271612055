import { signInWithRedirect } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styles from './styles'
import { SxProps, Theme } from '@mui/material'
import { createEvent } from '../../../api/analytics/api'

const useGoogleButton = (disabled?: boolean, setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>) => {
    const [loading, setLoading] = useState<boolean>(false)

    const onClick = async () => {
        createEvent({ eventId: 'google-sign-in-button', time: new Date().getTime() })
        if (disabled || loading) return

        setLoading(true)
        try {
            await signInWithRedirect({ provider: 'Google' })
        } catch (e: any) {
            let error = 'There was a problem signing you in with Google.'
            switch (e.name) {
                case 'UserAlreadyAuthenticatedException':
                    error = 'You are already signed in!'
                    break
                default:
                    console.log(e.name)
                    break
            }
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (setIsLoading) setIsLoading(loading)
    }, [loading])

    const containerStyles = {
        ...styles.container,
        '&:hover': { cursor: disabled || loading ? 'default' : 'pointer' }
    } as SxProps<Theme>

    return { onClick, containerStyles }
}

export default useGoogleButton
